import React, { useEffect, forwardRef, useRef, useState } from 'react';
import { FirstName, Barcode, Confirm, LabelDate, CrossIcon } from '../HemeIconLibrary';
import { HemaLabel, Button, SingleSelect, FormText, } from '../utils';
import HemaHeadingWithSubText from '../utils/HemaHeadingWithSubText';
import { useSelector, useDispatch } from 'react-redux';
import {
    getAllSponserAction,
    getAllSponserStudiesAction,
} from "../Actions/sponsers";
import { downloadSuppliesReportAction } from '../Actions/study';
import { Formik } from 'formik';

const SuppliesReport = forwardRef((props, ref) => {

    const { sponsers } = useSelector((state) => state);
    const [study, setStudy] = useState();
    const [sponsor, setSponsor] = useState();


    const dispatch = useDispatch();

    useEffect(() => {
        getAllSponserAction();
    }, []);

    useEffect(() => {
        if (Number.isInteger(sponsor) && sponsor != 0) {
            getAllSponserStudiesAction(sponsor);
        }
    }, [sponsor]);

    const setSponsorFieldValue = (x, id) => {
        setSponsor(id)
        if (Number.isInteger(id) && id != 0)
            getAllSponserStudiesAction(id);
    }

    return (
        <div className="w-full h-full px-[16px] pb-[34px] mt-[34px]">
            <Formik
                initialValues={{
                    sponsor: '',
                    study: '',
                    fromDate: '',
                    toDate: ''
                }}
                innerRef={ref}
                validate={(values) => {
                    const errors = {};

                    if (!values.sponsor) {
                        errors.sponsor = 'Required';
                    }
                    if (!values.study) {
                        errors.study = 'Required';
                    }

                    return errors;
                }}
                onSubmit={async (values) => {


                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    validateForm,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} className="flex flex-col justify-between w-full h-full m-auto overflow-auto ">

                    <div className="pr-4">
                        <div className="flex gap-[10px] justify-between items-end">
                            <HemaHeadingWithSubText heading="Supplies Report" sub="Select the sponser and study to get your supplies report" />
                        </div>
                        <div className="bg-white rounded-[5px] pt-[16px] pb-[10px] px-4 mt-[27px] mb-[13px] ">
                            <div className={`flex items-start gap-[16px] mb-3`}>
                                <div className={`w-full `}>
                                    <HemaLabel
                                        text="Sponsor"
                                        className={'mb-[10px]'}
                                        required={true}
                                        icon={<FirstName />}
                                    />


                                    <SingleSelect
                                        name={'sponsor'}
                                        onChange={handleChange}
                                        setFieldValue={(e, r) => {
                                            setSponsorFieldValue(e, r)
                                        }
                                        }
                                        value={sponsor}
                                        options={sponsers?.allSponsers?.filter((data) => data.isActive) || []}
                                        formikRef={ref}
                                    />
                                    {!sponsor && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>}

                                </div>

                                <div className={`w-full`}>
                                    <HemaLabel
                                        text="Study #"
                                        className={'mb-[10px]'}
                                        required={true}
                                        icon={<Barcode purple />}
                                    />

                                    <SingleSelect
                                        name={'study'}
                                        value={study ? study : null}
                                        options={sponsers?.sponerStudy?.filter((data) => data?.logisticOrchestratorQcApproved) || []}
                                        setFieldValue={setFieldValue}
                                    />
                                    {!values.study && <div className="error text-[red] text-[12px] pt-[2px]">Required</div>}
                                </div>

                            </div>
                            <div className={`flex items-start gap-[16px] mb-3`}>
                                <div className={`w-full`}>
                                    <HemaLabel
                                        text="From"
                                        className={'mb-[10px]'}
                                        required={false}
                                        icon={<LabelDate />}
                                    />

                                    <FormText
                                        type="date"
                                        name="fromDate"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        value={values.fromDate}
                                        placeholder={'Select a date'}
                                        max={new Date()?.toISOString().split('T')[0]}
                                        crossicon={<CrossIcon />}
                                        formikref={ref}
                                    />

                                </div>
                                <div className={`w-full  `}>
                                    <HemaLabel
                                        text="To"
                                        className={'mb-[10px]'}
                                        required={false}
                                        icon={<LabelDate />}
                                    />

                                    <FormText
                                        type="date"
                                        name="toDate"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        value={values.toDate}
                                        placeholder={'Type name to search and select'}
                                        formikref={ref}
                                        crossicon={<CrossIcon />}
                                    />

                                </div>
                            </div>

                            <Button
                                type="button"
                                text="Generate"
                                bg="bg-primary1"
                                color="text-white"
                                icon={<Confirm />}
                                // disabled={(study && sponsor) ? false : true}
                                cta={async () => {
                                    console.log('cta')
                                    if (Number.isInteger(values.study) && values.study != 0)
                                        await downloadSuppliesReportAction(values.study, values.fromDate, values.toDate);
                                }}
                            />
                        </div>

                    </div>
                    </form>
                )}
            </Formik>
        </div>
    );
});
export default SuppliesReport;